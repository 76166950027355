<template>
  <header
    :class="
      cn(
        'bg-main-primary flex justify-between items-center w-screen py-3 px-4 z-50 gap-2',
        header.class
      )
    "
  >
    <div class="flex gap-4 items-center w-full basis-[33%]">
      <CircleIcon
        size="2.5rem"
        class="bg-main-lightBg hover:!bg-main-lightBg/80"
        @click="handleBack"
      >
        <i class="fa fa-chevron-left text-white"></i>
      </CircleIcon>
      <div class="flex flex-col flex-1">
        <p class="text-base text-white font-semibold">{{ header.title }}</p>
        <div v-if="header.subtitle">
          <div v-if="isSubtitleArray">
            <Roles
              :items="(header.subtitle as string[])"
              :fontSize="'12px'"
              :textColor="'#ffffffc2'"
              :separatorWidth="'3px'"
              :separatorHeight="'3px'"
              :separatorColor="'#ffffffc2'"
            />
          </div>
          <p v-else class="text-xs text-white">{{ header.subtitle }}</p>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center gap-4 basis-[33%]">
      <slot name="menu" />
    </div>
    <div class="gap-4 flex basis-[33%] items-center justify-end">
      <slot name="actions" />
    </div>
  </header>
</template>
<script setup lang="ts">
import { computed, ref, type PropType } from "vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { cn } from "@/utils/cn";
import Roles from "@/components/general/Roles.vue";
import type { UserInterface } from "@/core/interfaces";
import { Link, usePage } from "@inertiajs/vue3";

export interface headerProps {
  title: string;
  subtitle?: string | string[];
  user?: UserInterface;
  class?: string | object;
  card?: object;
  buttonCallback?: Function;
}

const props = defineProps({
  header: {
    required: true,
    type: Object as PropType<headerProps>,
  },
});

const isSubtitleArray = computed(() => Array.isArray(props.header.subtitle));
console.log(props.header.buttonCallback);
const handleBack = () => {
  if (props.header.buttonCallback) {
    props.header?.buttonCallback();
  } else {
    window.history.back();
  }
};
</script>
