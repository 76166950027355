import Echo from "laravel-echo";
import { defineStore } from "pinia";
import Pusher from "pusher-js";
interface State {
  socket: Echo | null;
}

export const useWSStore = defineStore("wsStore", {
  state: (): State => ({
    socket: null,
  }),
  getters: {},
  actions: {
    initWS() {
      if (this.socket) return;

      window.Pusher = Pusher;

      this.socket = new Echo({
        broadcaster: "pusher",
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? "mt1",
        wsHost: import.meta.env.VITE_PUSHER_HOST,
        wssHost: import.meta.env.VITE_PUSHER_HOST,
        wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
        wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
        forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? "https") === "https",
        enabledTransports: ["ws", "wss"],
      });

      // LIFECYCLE
      this.socket.connector.pusher.connection.bind("connected", () => {
        console.log("[DEBUG] WebSocket :: connected");
      });
      this.socket.connector.pusher.connection.bind("disconnected", () => {
        console.log("[DEBUG] WebSocket :: disconnected");
      });
      this.socket.connector.pusher.connection.bind("unavailable", () => {
        console.log("[DEBUG] WebSocket :: unavailable");
      });
      this.socket.connector.pusher.connection.bind("connecting", () => {
        console.log("[DEBUG] WebSocket :: connecting...");
      });
    },
  },
});
