<template>
  <div
    class="px-[15px] items-center !mb-[0px]"
    :class="
      isNoPaddingComponent && !isNoLineHeightComponent
        ? 'p-0'
        : isNoPaddingComponent && isNoLineHeightComponent
        ? 'p-0 leading-none'
        : null
    "
    :style="`padding:${padding}`"
    @click="
      (e) => {
        e.stopPropagation();
      }
    ">
    <p
      class="font-normal text-[14px] leading-[20px] mb-[0px] [&>*:first-child]:line-clamp-3"
      :class="{
        '[&>*:first-child]:line-clamp-none': ReadmoreToggle,
        'p-0': isNoPaddingComponent,
        'pb-[10px] pt-[10px]': !isNoPaddingComponent,
      }"
      :style="`color:#${color ?? '141414'}; font-size:${fsize};`">
      <!-- Use the h() function output as the content -->
      <span ref="full" class="overflow-hidden">
        <formatTextWithMention />
      </span>

      <span
        v-if="Readmore"
        class="text-text-800 cursor-pointer hover:underline"
        @click="ReadmoreToggle = !ReadmoreToggle">
        Read {{ ReadmoreToggle ? "less" : "more" }}
      </span>
    </p>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  resolveComponent,
  h,
  createTextVNode,
  nextTick,
  watch,
  onUpdated,
} from "vue";
import { Link } from "@inertiajs/vue3";

const props = defineProps({
  text: String,
  color: String,
  fsize: String,
  padding: String,
  limit: Number,

  isNoPaddingComponent: Boolean,
  readMoreText: String,
  isNoLineHeightComponent: {
    type: Boolean,
    default: false,
  },
});

const full = ref();
const Readmore = ref(false);
const ReadmoreToggle = ref(false);

function formatTextWithMention() {
  const parts = props.text?.split(/(<span class="mention".*?<\/span>)/g); // Split by mention tags
  const vnodes = [];

  parts.forEach((part) => {
    const match = part.match(
      /<span class="mention" data-mention="@(.*?)">(.*?)<\/span>/,
    );
    if (match) {
      const public_username = match[1];
      const fullName = match[2];
      // Push the Link VNode for mentions
      vnodes.push(
        h(
          Link,
          {
            class: "text-switcherBlue-500",
            href: route("user.profile.index", { user: public_username }),
          },
          fullName,
        ),
      );
    } else {
      // First strip HTML tags and then decode HTML entities
      const cleanedText = decodeHtmlEntities(
        part.replace(/<\/?[^>]+(>|$)/g, ""),
      );
      vnodes.push(createTextVNode(cleanedText)); // Ensures plain text is handled correctly
    }
  });

  return h("span", { class: "whitespace-pre-line" }, vnodes);
}

function decodeHtmlEntities(text) {
  return text
    .replace(/&nbsp;/g, " ") // Replace &nbsp; with a space
    .replace(/&amp;/g, "&") // Replace &amp; with &
    .replace(/&lt;/g, "<") // Replace &lt; with <
    .replace(/&gt;/g, ">") // Replace &gt; with >
    .replace(/&quot;/g, '"') // Replace &quot; with "
    .replace(/&#039;/g, "'"); // Replace &#039; with '
}
const isReadMore = () => {
  if (full.value) {
    if (full.value.offsetHeight < full.value.scrollHeight)
      Readmore.value = true;
    else Readmore.value = false;
  }
};
watch(
  () => props.text,
  (newVal) => {
    nextTick(() => {
      ReadmoreToggle.value = false;
      isReadMore();
    });
  },
);
onMounted(() => {
  isReadMore();
});
</script>
