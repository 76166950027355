<template>
  <div
    class="flex items-center justify-start !cursor-pointer transition duration-150 gap-2.5 w-full">
    <div class="flex items-center w-[calc(100%-40px)] gap-2.5" v-if="user">
      <Link
        :href="route('user.profile.index', user.public_username)"
        v-if="isLink">
        <img
          :src="user.avatar_image ?? ProfileNoPic"
          alt="Image"
          :class="
            cn(
              'w-[50px] h-[50px] object-cover max-h-[50px] max-w-[50px] min-h-[50px] min-w-[50px] rounded-full',
              avatarClass,
            )
          " />
      </Link>
      <img
        v-else
        :src="user.avatar_image ?? ProfileNoPic"
        alt="Image"
        :class="
          cn(
            'w-[50px] h-[50px] object-cover max-h-[50px] max-w-[50px] min-h-[50px] min-w-[50px] rounded-full',
            avatarClass,
          )
        " />
      <div class="flex flex-col justify-between w-full">
        <div
          :class="
            cn(
              'text-[14px] font-[600] transition duration-150 hoverHeading',
              userProps.class,
            )
          ">
          {{ user.full_name }}
          <span
            v-if="ownerTag"
            class="font-[400] text-[10px] bg-[#3888ff65] px-[8px] py-[4px] rounded-[20px]">
            Owner
          </span>
        </div>
        <div
          :class="
            cn(
              'relative overflow-hidden m-0 p-0 w-full min-w-[200px]',
              roleClass,
            )
          ">
          <Roles
            v-if="showRoles && user.roles && user.roles.length !== 0"
            :items="user.roles.map((v) => v.name)"
            v-bind="roleProps" />
          <canvas class="hidden"></canvas>
        </div>
        <div
          class="text-[12px] text-main-lightGray hidden xl:flex xl:gap-1 items-center"
          v-if="showLocation">
          <i class="fa-sharp far fa-location-dot"></i>
          {{ user.location }}
        </div>
      </div>
    </div>
    <div class="friendIconWrapper" v-if="showRelationship">
      <!--
                            TODO:: Create relationship buttons in a separated component
                            [
                                Add Friend,
                                Remove Friend,
                                Accept Request,
                                Decline Request,
                                Cancel Request
                                Message
                            ]
                         -->
      <i class="fa fa-user-plus" v-if="showAddButton"></i>
    </div>
    <div v-if="slots.actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import ProfileNoPic from "@/assets/images/profile-no-pic.png";
import Roles from "@/components/general/Roles.vue";
import type { UserInterface } from "@/core/interfaces";
import { useAuthStore } from "@/store/Auth";
import { useUserStore } from "@/store/User";
import { cn } from "@/utils/cn";
import { Link } from "@inertiajs/vue3";
import type { PropType } from "vue";
import { computed, useSlots } from "vue";
const authStore = useAuthStore();
const authUser = computed(() => authStore.getUser);
const userStore = useUserStore();
const friends = computed(() => userStore.friends || []);
const showAddButton = computed(
  () =>
    friends.value.findIndex((f) => f.id === props.user?.id) === -1 &&
    props.user?.id !== authUser.value.id,
);
const props = defineProps({
  user: {
    type: Object as PropType<UserInterface>,
    required: true,
  },
  avatarClass: {
    type: String,
    required: false,
  },
  showLocation: {
    type: Boolean,
    default: true,
  },
  showRelationship: {
    type: Boolean,
    default: true,
  },
  userProps: {
    type: Object,
    required: false,
    default: {
      class: "",
    },
  },
  showRoles: {
    type: Boolean,
    default: true,
  },
  roleClass: {
    type: String || Object,
    required: false,
  },
  roleProps: {
    type: Object,
    required: false,
    default: {
      fontSize: "12px",
      textColor: "#000",
      separatorWidth: "4px",
      separatorHeight: "4px",
      separatorColor: "#8c8c8c",
    },
  },
  isLink: {
    type: Boolean,
    default: true,
  },
  ownerTag: {
    type: Boolean,
    default: false,
  },
});
const roleProps = computed(() => {
  const defaultValue = {
    fontSize: "12px",
    textColor: "#000",
    separatorWidth: "4px",
    separatorHeight: "4px",
    separatorColor: "#8c8c8c",
  };
  return { ...defaultValue, ...props.roleProps };
});
const slots = useSlots();
</script>
