<template>
  <EditContentDialog
    v-if="dialogStore.isOpened(DialogType.UPLOAD_CONTENT)"
    :content="dialogStore[DialogType.UPLOAD_CONTENT].data?.content"
    @close="dialogStore[DialogType.UPLOAD_CONTENT].events?.close"
    @update="dialogStore[DialogType.UPLOAD_CONTENT].events?.update" />
  <EditPostDialog
    v-if="dialogStore.isOpened(DialogType.POST_MANAGER)"
    @close="dialogStore.hide(DialogType.POST_MANAGER)"
    @confirm="dialogStore[DialogType.POST_MANAGER].events?.confirm" />
  <Confirm
    v-if="dialogStore.isOpened(DialogType.CONTENT_SHARE_POST)"
    class="bg-neutral-900"
    layout="vertical"
    color="primary-light"
    action="Share as post"
    @confirm="dialogStore[DialogType.CONTENT_SHARE_POST].events?.confirm"
    @close="dialogStore.hide(DialogType.CONTENT_SHARE_POST)">
    <template #icon>
      <i
        class="fa-duotone fa-solid fa-party-horn fa-3x"
        style="
          --fa-primary-color: #fed920;
          --fa-secondary-color: #d80e0e;
          --fa-secondary-opacity: 0.6;
        "></i>
    </template>
    <template #title>
      <p class="text-white text-lg font-semibold">
        Congratulations, your content is now available to view on your
        portfolio!
      </p>
    </template>
    <template #description>
      <p class="text-sm text-main-disabled text-center">
        Would you like to share your latest portfolio addition with the
        bizlyCommunity?
      </p>
    </template>
  </Confirm>
  <CollectionDialog
    v-if="dialogStore.isOpened(DialogType.UPLOAD_CONTENT_COLLECTION)"
    :content="dialogStore[DialogType.UPLOAD_CONTENT_COLLECTION].data?.content"
    @close="dialogStore[DialogType.UPLOAD_CONTENT_COLLECTION].events?.close" />
  <ExternalLink
    v-if="dialogStore.isOpened(DialogType.EXTERNAL_LINK)"
    @close="dialogStore[DialogType.EXTERNAL_LINK].events?.close"
    @confirm="dialogStore[DialogType.EXTERNAL_LINK].events?.confirm" />
  <ManageGroup
    v-if="dialogStore.isOpened(DialogType.MANAGE_GROUP)"
    @close="dialogStore[DialogType.MANAGE_GROUP].events?.close" />
  <ManageGroups
    v-if="dialogStore.isOpened(DialogType.MANAGE_GROUPS)"
    @close="dialogStore[DialogType.MANAGE_GROUPS].events?.close" />
  <Invitations
    v-if="dialogStore.isOpened(DialogType.INVITATIONS)"
    :visible="dialogStore.isOpened(DialogType.INVITATIONS)"
    @close="dialogStore[DialogType.INVITATIONS].events?.close" />
  <MobileApp
    v-if="dialogStore.isOpened(DialogType.MOBILE_APP)"
    @close="dialogStore[DialogType.MOBILE_APP].events?.close" />
  <ManageLink
    v-if="dialogStore.isOpened(DialogType.MANAGE_LINK)"
    @close="dialogStore[DialogType.MANAGE_LINK].events?.close"
    @confirm="dialogStore[DialogType.MANAGE_LINK].events?.confirm"
    @delete="dialogStore[DialogType.MANAGE_LINK].events?.delete" />
  <ManageBizcard
    v-if="dialogStore.isOpened(DialogType.MANAGE_BIZCARD)"
    @close="dialogStore[DialogType.MANAGE_BIZCARD].events?.close" />
</template>
<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import { useDialogStore, DialogType } from "@/store/Dialog";
import ExternalLink from "@/components/dialogs/ExternalLink.vue";
import ManageGroup from "@/components/dialogs/contacts/ManageGroup.vue";
import ManageGroups from "@/components/dialogs/contacts/ManageGroups.vue";
import Invitations from "@/components/dialogs/invitations/Main.vue";
import MobileApp from "@/components/dialogs/MobileApp.vue";
import ManageLink from "@/components/dialogs/bizcard/home/ManageLink.vue";
import ManageBizcard from "@/components/dialogs/bizcard/home/ManageBizcard.vue";
import EditContentDialog from "@/components/dialogs/content/Main.vue";
import CollectionDialog from "@/components/dialogs/collection/Index.vue";
import Confirm from "@/components/dialogs/Confirm.vue";
import EditPostDialog from "@/components/dialogs/social-posts/EditPost.vue";

const dialogStore = useDialogStore();
</script>
