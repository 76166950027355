<template>
  <div class="flex flex-col gap-4">
    <div
      class="sticky top-0 p-4 bg-title w-full z-10 shadow-md flex flex-col gap-2">
      <p
        class="uppercase text-xs text-surface-400/95"
        v-if="collaborators.length">
        <strong>{{ model.length }}/{{ max }}</strong>
        role{{ model.length > 1 || model.length == 0 ? "s" : "" }}
        selected
      </p>
      <IconField iconPosition="left" class="relative">
        <i class="far fa-magnifying-glass !text-surface-400"></i>
        <InputText
          @input="handleSearch"
          v-model="lazyLoad.keyword"
          placeholder="Search a collaborator by name"
          class="bg-white/10 w-full focus:!outline-none focus:!border-0 focus:!ring-0 hidden md:block transition-all duration-150 ease-in-out text-white" />
      </IconField>
    </div>
    <div class="space-y-x px-4">
      <i
        class="fa fa-circle-arrow-left fa-xl text-white cursor-pointer"
        @click="handleBack"
        v-if="rolesList.length"></i>
      <div
        class="h-[calc(70vh-50px)] overflow-y-auto scroll-hidden"
        v-infinite-scroll="getRolesData"
        v-if="rolesList.length && !loading">
        <div
          class="flex justify-between items-center mt-2 hover:bg-surface-500/50 cursor-pointer rounded-md"
          :key="item.id"
          :for="`roles_${item.id}`"
          v-for="item in rolesList">
          <label
            class="text-sm text-white flex-1 py-2 cursor-pointer"
            :for="`roles_${item.id}`">
            {{ item.name }}
          </label>

          <Checkbox
            v-model="model"
            :value="item.id"
            :inputId="`roles_${item.id}`"
            :outlined="true"
            color="lightBlue"
            size="lg"
            :disabled="
              (user.roles.length >= max &&
                user.roles.findIndex((i) => i.id === item.id) === -1) ||
              (user.roles.length === 1 &&
                user.roles.findIndex((i) => i.id === item.id) !== -1)
            "
            @change="handleToggleRole" />
        </div>
      </div>
      <div
        class="px-2.5 pb-2.5 flex items-center justify-center"
        v-if="loading">
        <i class="fa fa-spinner-third fa-spin text-white"></i>
      </div>
      <AddItem
        :value="lazyLoad.keyword"
        :disabled="user.roles.length >= max"
        :callback="handleAddCustomRole"
        v-if="!lazyLoad.hasData && lazyLoad.keyword !== null && !loading" />
    </div>
  </div>
</template>
<script setup lang="ts">
import type { RolesInterface, UserInterface } from "@/core/interfaces";
import { onMounted, reactive, ref } from "vue";
import _debounce from "lodash/debounce";
import { API } from "@/core/api";
import AddItem from "@/components/general/AddItem.vue";
import { vInfiniteScroll } from "@vueuse/components";
import _ from "lodash";
const props = defineProps({
  collaborators: {
    type: Array as () => UserInterface[],
    required: true,
  },
  modelValue: {
    type: Array as () => number[],
    required: true,
  },
});

const emit = defineEmits(["back"]);

const rolesAPI = new API.ClientRoles();
const model: any = defineModel({ required: true });
const user = defineModel("user", {
  type: Object as () => UserInterface,
  required: true,
});
const max = ref(3);
const rolesList = ref<any>([]);
const lazyLoad = reactive<any>({
  start: 0,
  size: 25,
  keyword: null,
  initialValues: [],
  hasData: true,
});
const loading = ref(false);

onMounted(() => {
  console.log(user.value.roles);
  lazyLoad.start = 0;
  lazyLoad.size = 25;
  lazyLoad.initialValues = user.value.roles.map((role: RolesInterface) => ({
    id: role.id,
    name: role.name,
  }));
  lazyLoad.hasData = true;
  getRolesData();
});

const RolesSearch = () => {
  lazyLoad.start = 0;
  rolesList.value = [];
  loading.value = false;
  getRolesData();
};

const getRolesData = async () => {
  loading.value = true;
  let response = await rolesAPI.get(lazyLoad);
  response = response.data;
  rolesList.value.push(...response);
  if (response.length < lazyLoad.size) lazyLoad.hasData = false;
  lazyLoad.start = rolesList.value.length;
  loading.value = false;
};

const handleToggleRole = (e: any) => {
  const role = rolesList.value.find(
    (v: RolesInterface) => v.id == e.target.value,
  );
  if (!role || !user.value) return; // idk if this is correct, i guess so
  if (e.target.checked) {
    user.value.roles.push(role);
  } else {
    user.value.roles = user.value?.roles.filter((v: any) => v.id !== role.id);
  }
};

const handleAddCustomRole = () => {
  if (!user.value) return;
  if (user.value.roles.length >= max.value) return;
  const roleId = -model.value.length;
  const role: RolesInterface = {
    id: roleId,
    name: lazyLoad.keyword,
  };
  user.value.roles.push(role);
  model.value.push(roleId);
  lazyLoad.initialValues.push(role);
  lazyLoad.keyword = null;
  getRolesData();
};

const handleSearch = _debounce(() => {
  RolesSearch();
}, 250);

const handleBack = () => {
  emit("back");
};
</script>
