import {
  type UserInterface,
  type NotificationInterface,
} from "@/core/interfaces";
import { defineStore } from "pinia";
import { router } from "@inertiajs/vue3";
import { reactive, ref, computed, onMounted, watch } from "vue";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";

interface State {
  notifications: NotificationInterface[];
  lazyload: {
    start: number;
    size: number;
    no_more_results: Boolean;
    isLoading: Boolean;
  };
}
export const useNotificationStore = defineStore("notificationStore", {
  state: (): State => ({
    notifications: [],
    lazyload: {
      start: 0,
      size: 15,
      no_more_results: false,
      isLoading: false,
    },
  }),
  getters: {
    read: (state): NotificationInterface[] =>
      state.notifications.filter((n: NotificationInterface) => n.is_read) ?? [],
    unread: (state): NotificationInterface[] =>
      state.notifications.filter((n: NotificationInterface) => !n.is_read) ??
      [],
  },
  actions: {
    async getNotifications() {
      const notificationApi = new API.Notifications();
      if (!this.lazyload.no_more_results) {
        const { data: response }: { data: NotificationInterface[] } =
          await notificationApi.get({ ...this.lazyload });
        if (this.lazyload.start) {
          if (response.length) {
            this.notifications = [...this.notifications, ...response];
          }
        } else {
          this.notifications = response;
        }
        if (response.length < (this.lazyload.size ?? 0)) {
          this.lazyload.no_more_results = true;
        }
        this.lazyload.start = this.notifications.length;
        this.lazyload.size = 10;
      }
      if (this.lazyload.isLoading === true) {
        this.lazyload.isLoading = false;
      }
    },
    readAll() {
      const notificationApi = new API.Notifications();
      notificationApi.markAllAsRead().then(() => {
        // emit("read-all");
        this.notifications.forEach((n) => (n.is_read = true));
      });
    },
    async handleCollabRequest(id: number, action: string) {
      const { error, success } = useToastr();
      const notificationApi = new API.Notifications();
      const notification = this.notifications.find((n) => n.id === id);
      if (!notification) return;
      try {
        const response = await notificationApi.toggleCollaboration(
          notification.id,
          action,
        );
        if (response.hasOwnProperty("success")) {
          this.notifications.splice(
            this.notifications.indexOf(notification),
            1,
          );
          success(response.success);
        } else if (response.hasOwnProperty("error")) {
          error(response.error);
        }
      } catch (err: any) {
        console.log("[DEBUG] Error in handleCollabRequest: ", err);
        error(err.response.data);
      }
    },
    async handleConnectionRequest(notificationId: number, action: string) {
      try {
        const notificationApi = new API.Notifications();

        const notificationIdx = this.notifications.findIndex(
          (i) => i.id === notificationId,
        );
        if (notificationIdx !== -1) {
          const { code } = await notificationApi.toggleConnection(
            this.notifications[notificationIdx].id,
            action,
          );
          if (code === "invite_accepted") {
            // TODO: Change this to enum
            this.notifications.splice(notificationIdx, 1);
          } else if (code === "invite_declined") {
            this.notifications[notificationIdx].is_read = true;
          } else {
            throw new Error("An error has occurred!");
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    AddNotification(notification: NotificationInterface) {
      this.notifications.unshift(notification);
    },
    RemoveNotification(notification: NotificationInterface) {
      const notificationIdx = this.notifications.findIndex(
        (n) => n.id === notification.id,
      );
      if (notificationIdx !== -1) {
        this.notifications.splice(notificationIdx, 1);
      }
    },
  },
});
