<template>
  <div>
    <slot />
    <DialogList />
  </div>
</template>
<script setup lang="ts">
import {
  onBeforeMount,
  provide,
  reactive,
  ref,
  computed,
  onMounted,
  watch,
} from "vue";
import type { UserInterface } from "@/core/interfaces";
import { useStore } from "@/store";
import { useAuthStore } from "@/store/Auth";
import { useUserStore } from "@/store/User";
import { parseFlashData } from "@/utils/toast";
import { usePage } from "@inertiajs/vue3";
import DialogList from "@/components/dialogs/DialogsList.vue";
import { useWSStore } from "./store/ws/WebSocket";
import mitt from "mitt";
import { useNotificationStore } from "@/store/Notifications";

/* ---------- REACTIVE DATA ---------- */

/* EVENT EMITTER (EVENT BUS) */
const emitter = mitt();
provide("emitter", emitter);

const notificationStore = useNotificationStore();
const page = usePage();
const authStore = useAuthStore();
const store = useStore();
const userStore = useUserStore();
const ToastList = computed(() => page.props.flash_data);
const token = computed<string | null>(() => page.props.token as string | null);
const authUser = computed(() => ({
  ...(page.props.auth_user as UserInterface),
}));
const canSubscribe = computed(() => authUser.value?.id && socket.value);

const wsStore = useWSStore();
const socket = computed(() => wsStore.socket);
const old_user_id = ref();
/* ---------- WATCHERS ---------- */
watch(
  ToastList,
  (newVal) => {
    parseFlashData(newVal);
  },
  { immediate: true, deep: true },
);

watch(
  () => token.value,
  () => {
    setUserData();
  },
);

// LISTEN TO USER EVENTS WHEN USER IS LOADED AND IS NOT NULL
watch(
  () => authUser.value,
  (newVal, oldVal) => {
    authStore.setUser(newVal);
    old_user_id.value = oldVal.id;
  },
  { deep: true },
);
watch(canSubscribe, (newVal, oldVal) => {
  if (newVal) {
    // If user.id exists and socket is ready, subscribe to the channels
    socket.value
      ?.private(`notifications.user.${authUser.value.id}`)
      .listen(".notification-event", notificationEventAction);
    socket.value
      ?.private(`connection-requests.user.${authUser.value.id}`)
      .listen(".connection-request-event", connectionRequestAction);
  } else if (oldVal && !newVal && socket.value) {
    // If user.id does not exist (Logged out) and socket is ready, unsubscribe to the channels
    socket.value?.leave(`notifications.user.${old_user_id.value}`);
    socket.value?.leave(`connection-requests.user.${old_user_id.value}`);
  }
});
/* ---------- FUNCTIONS ---------- */

/* WS ACTIONS */
const notificationEventAction = (data) => {
  switch (data.type) {
    case "created":
      notificationStore.AddNotification(data.notification);
      break;
    case "deleted":
      notificationStore.RemoveNotification(data.notification);
      break;
    default:
      console.log("notificationEventAction", data);
      return;
  }
  console.log("notificationEventAction", data);
};
const connectionRequestAction = (data) => {
  console.log("connectionRequestAction", data);
};

onMounted(() => {
  wsStore.initWS();
  notificationStore.getNotifications();
});
onBeforeMount(() => {
  setUserData();
  store.getNewToBizlyUsers();
  store.getQuoteOfTheDay();
});

const setFriendsList = async () => {
  if (token.value) {
    await userStore.getFriends();
  } else {
    await userStore.emptyFriendsList();
  }
};

const setUserData = () => {
  authStore.setToken(token.value as string | null);
  authStore.setUser(authUser.value);
  setFriendsList();
  notificationStore.getNotifications();
};
</script>
