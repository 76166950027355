<template>
  <CustomCard customClass="relative">
    <template #content>
      <div
        v-if="form.processing"
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center bg-white bg-opacity-50 w-full h-full z-50">
        <i class="fas fa-spinner-third fa-spin text-primary-500"></i>
      </div>
      <div class="flex gap-0.5 items-center">
        <div class="flex flex-col gap-0.5">
          <span class="font-semibold">Are you available to work?</span>
          <span class="text-sm text-main-secondary">
            Enable to show you’re open to collaborate!
          </span>
        </div>
        <label
          v-if="isEditing"
          class="relative inline-flex items-center cursor-pointer h-max ms-auto">
          <input
            type="checkbox"
            class="sr-only peer"
            v-model="form.availability"
            @change="handleSave" />
          <div
            class="w-[36px] h-[20px] bg-gray-400 rounded-full peer-focus:ring-none peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-main-success disabled:peer-checked:bg-main-disabled"></div>
        </label>
      </div>
    </template>
  </CustomCard>
</template>

<script lang="ts" setup>
import { computed, watch, type PropType } from "vue";
import CustomCard from "@/components/general/CustomCard.vue";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import { useUserBizcardStore } from "@/store/UserBizcard";
import { router, useForm } from "@inertiajs/vue3";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";
import { useAuthStore } from "@/store/Auth";

/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});

/* -------- COMPOSABLE -------- */
const bizcardStore = useUserBizcardStore();
const bizCardServicesAPI = new API.BizcardServices();
const authStore = useAuthStore();
const { success, error } = useToastr();

/* -------- STATE -------- */
const isEditing = computed(() => bizcardStore.isEditing);

const form = useForm({
  availability: props.card.available_to_work,
});
/* -------- METHODS -------- */
const handleSave = async () => {
  if (form.processing) return;
  form.processing = true;
  try {
    const response = await bizCardServicesAPI.updateAvailability(form);
    if (response.error) {
      error(response.error);
    } else {
      success("Availability updated successfully");
      form.defaults();
      form.reset();
      router.reload();
      if (authStore.user) {
        authStore.user.bizcard.available_to_work = form.availability;
      }
    }
  } catch (e: any) {
    console.error(e);
    error("Error: " + e.message);
  } finally {
    form.processing = false;
  }
};
/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
watch(
  () => authStore.user,
  (newVal) => {
    form.availability = newVal?.bizcard?.available_to_work ?? false;
  },
  { deep: true },
);
</script>
