<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'relative p-0 w-full flex-1 flex flex-col ',
      root: ' bg-main-lightBg max-w-[900px] w-full rounded-[16px] h-screen scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section
      id="header"
      class="p-2 !bg-main-primary text-white border-b-[1px] border-surface-500 sticky top-0 z-20 rounded-t-[16px]">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">
          {{ form.id ? "Edit Portfolio Content" : "Portfolio Content" }}
        </h1>
        <CircleIcon class="bg-main-secondary" @click="$emit('close')">
          <i class="fa fa-x"></i>
        </CircleIcon>
      </div>
    </section>
    <ContentComponent
      v-model="form"
      :contentTypeSelector="true"
      :galleryManager="true"
      :classes="{
        left: 'w-[400px] max-h-[88vh]',
        right: 'w-[500px] max-h-[88vh]',
      }"></ContentComponent>
    <section
      id="footer"
      class="!bg-main-primary sticky bottom-0 z-20 rounded-b-[16px]">
      <div class="flex justify-end items-center py-2.5 px-4 gap-2">
        <button
          :disabled="form.items.length === 0"
          @click="ManageCoverDialogVisible = true"
          class="border-[1px] border-white text-white rounded text-xs w-[180px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent hover:bg-switcherBlue-500 disabled:hover:border-[1px] hover:border-0 disabled:border-main-disabled disabled:text-main-disabled">
          Manage Cover
        </button>
        <PrimeVueButton
          :disabled="!canUpload || isUploading"
          class="!bg-switcherBlue-500 hover:!bg-switcherBlue-500/80 text-white disabled:!border-[1px] disabled:!bg-transparent disabled:!border-main-disabled disabled:!text-main-disabled rounded text-xs w-[180px] h-[40px]"
          @click="handleUpload"
          :loading="isUploading"
          :label="form.id ? 'Update' : 'Upload'"></PrimeVueButton>
      </div>
    </section>
    <div class="absolute inset-0 bg-black/20 z-50" v-if="isUploading"></div>
  </Dialog>

  <ManageCoverDialog
    v-if="ManageCoverDialogVisible"
    @close="ManageCoverDialogVisible = false"
    v-model="form" />
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import ManageCoverDialog from "./parts/ManageCover.vue";
import PrimeVueButton from "primevue/button";
import { router, useForm } from "@inertiajs/vue3";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";
import { useHomeFeedStore } from "@/store/HomeFeed";
import { route } from "ziggy-js";
import ContentComponent from "./parts/Content.vue";
import { useUserBizcardStore } from "@/store/UserBizcard";
import { useDialogStore, DialogType } from "@/store/Dialog";
import { useAuthStore } from "@/store/Auth";

const authStore = useAuthStore();
const user = computed(() => authStore.getUser);
const dialogStore = useDialogStore();
const homeFeedStore = useHomeFeedStore();
const bizcardStore = useUserBizcardStore();
const { success, error } = useToastr();

const emit = defineEmits(["close", "update"]);
/* ---------- REACTIVE DATA ----------*/
const props = defineProps({
  content: {
    type: Object,
    default: null,
  },
});
const contentAPI = new API.Content();
const ManageCoverDialogVisible = ref(false);

const form = useForm<any>({
  id: props?.content?.id ?? null,
  isValid: {},
  content_type: props?.content?.content_type ?? null,
  title: props?.content?.title ?? null,
  description: props?.content?.description ?? null,
  categories: props?.content?.categories ?? [],
  tags: props?.content?.tags ?? [],
  project_types: props?.content?.project_types ?? [],
  collaborators: props?.content?.collaborators ?? [],
  project_roles:
    props?.content?.owner_roles?.map((i) => ({ id: i.id, name: i.name })) ?? [],
  project_companies: props?.content?.organizations ?? [],
  project_completed_on: props?.content?.completion_year ?? null,
  related_services: props?.content?.related_services?.map((i) => i.id) ?? [],
  items: props?.content?.items ?? [],
  thumbnail: {
    video: props?.content?.image_kit_id_16_9 ?? null,
    square: props?.content?.thumbnail_image_kit_id ?? null,
    default: props?.content?.thumbnail_image_kit_id ?? null,
  },
  is_featured: props?.content?.is_featured,
});

const canUpload = computed(() => {
  return (
    form.items.length > 0 &&
    form.title &&
    !Object.values(form.isValid).includes(false) &&
    !form.items.some((i: any) => !i.valid) &&
    isCollaboratorsValid.value &&
    hasValidCover.value &&
    linkGalleryValidation.value
  );
});
const hasValidCover = computed(
  () =>
    !!form.thumbnail.video &&
    !!form.thumbnail.square &&
    !!form.thumbnail.default,
);
const linkGalleryValidation = computed(() => {
  if (form.content_type === "link_gallery") {
    const checkTitle = form.items.length > 1;
    if (
      form.items.some((i) => (checkTitle && !!!i.title) || !i.thumbnail.default)
    )
      return false;
  }
  return true;
});
const isCollaboratorsValid = computed(() =>
  form.collaborators
    ? form.collaborators.length
      ? !form.collaborators.some((i) => !i.roles.length)
      : true
    : true,
);
const isUploading = ref(false);
/* ---------- FUNCTIONS ----------*/
const handleSharePost = async () => {
  const data = dialogStore.getData(DialogType.CONTENT_SHARE_POST);
  console.log(data);
  if (data.id === null) {
    return;
  }
  let response = await contentAPI.shareAsPost(data.id);
  response = response.data;
  dialogStore.hide(DialogType.CONTENT_SHARE_POST);
  console.log(user.value);
  dialogStore.show(DialogType.POST_MANAGER, undefined, {
    post: response,
    user: user.value,
  });
};
const handleUpload = async () => {
  let response;
  try {
    isUploading.value = true;
    if (props.content) {
      response = await contentAPI.update(form);
    } else {
      response = await contentAPI.create(form);
    }
    if (response.hasOwnProperty("data")) {
      // UPDATE DATA BASED ON ROUTE
      if (route().current() === "social_posts.index")
        router.reload({ only: ["post"] });
      else if (route().current() === "home")
        homeFeedStore.editContent(response.data);
      else if (route().current() === "bizcards.content.get") {
        router.reload({ only: ["content"] });
      } else if (route().current() === "user.profile.index") {
        bizcardStore.editContent(response.data);
      }
      if (props.content) {
        // CONTENT WAS REMOVED FROM FEATURED
        if (props?.content?.is_featured && !response.data.is_featured) {
          console.log("REMOVED");
        }
        // CONTENT WAS ADDED TO FEATURED
        else if (!props?.content?.is_featured && response.data.is_featured) {
          console.log("ADDED");
        }
        success("Content updated successfully!");
        emit("update", response.data);
      }
      if (!form.id) {
        dialogStore.show(DialogType.CONTENT_SHARE_POST, response.data.id, {
          id: response.data.id,
        });
        dialogStore.setEvents(DialogType.CONTENT_SHARE_POST, {
          confirm: () => handleSharePost(),
        });
      }
      emit("close", response.data.id);
    } else {
      error(response);
    }
  } catch (err) {
    console.error(err);
    error("Something went wrong!");
  }
  isUploading.value = false;
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
