<template>
  <InfoCard
    v-if="
      card.contact_information.length > 0 &&
      card.contact_information.some(
        (contact) =>
          contact.type?.toLowerCase() === 'office' ||
          contact.type?.toLowerCase() === 'personal'
      )
    "
  >
    <template #title>
      <div class="flex justify-between items-center">
        <h3 class="text-lg font-medium">Contact</h3>
        <i
          v-if="is_editing && is_own_profile"
          class="fal fa-pencil text-primary-500 cursor-pointer text-base"
          @click="() => (manageContactVisible = true)"
        ></i>
      </div>
    </template>
    <template #content>
      <div class="flex flex-col gap-4 min-w-[400px]">
        <div
          v-for="value in card.contact_information"
          class="flex flex-col gap-2 bg-surface-100 rounded-md p-4"
        >
          <p class="uppercase text-surface-500 text-sm">
            {{ value.type }}
          </p>
          <div class="flex gap-0.5">
            <Avatar
              :image="value.company?.image ?? NoCompanyImage"
              @error="handleCompanyError"
              class="!size-10"
              shape="circle"
              v-if="value.type?.toLowerCase() == 'office'"
            />
            <div class="flex flex-col gap-1">
              <div class="text-base font-medium" v-if="value.company?.name">
                {{ value.company.name }}
              </div>
              <div
                class="flex flex-col gap-0.5 border-l-2 border-l-surface-300 pl-2 text-sm"
              >
                <a v-if="value.email">{{ value.email }}</a>
                <a v-if="value.phone">{{ value.phone }}</a>
                <a v-if="value.location">{{ value.location }}</a>
              </div>
              <div
                class="flex items-center gap-2"
                v-if="value.visible_to_out_of_network === false"
              >
                <i class="fal fa-eye-slash text-main-secondary text-sm"></i>
                <span class="text-[#595959] text-[10px] md:text-[12px]">
                  This info is hidden to people outside of your network.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </InfoCard>
  <ManageContact
    :contact="card.contact_information ?? []"
    v-if="manageContactVisible"
    @close="manageContactVisible = false"
  />
</template>

<script lang="ts" setup>
import type { PropType } from "vue";
import InfoCard from "@/components/general/CustomCard.vue";
import type { BizCardInterface, UserInterface } from "@/core/interfaces";
import NoCompanyImage from "@/assets/images/new_icons/company_dummy.svg";
import { ref } from "vue";
import ManageContact from "@/components/dialogs/bizcard/info/ManageContact.vue";
import { computed } from "vue";
import image from "@/assets/tailwind-preset/image";
import { useUserBizcardStore } from "@/store/UserBizcard";
/* -------- PROPS -------- */
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});

/* -------- COMPOSABLE -------- */
const bizcardStore = useUserBizcardStore();

/* -------- STATE -------- */
const is_editing = computed(() => bizcardStore.isEditing);
const is_own_profile = computed(() => bizcardStore.own_profile);

const manageContactVisible = ref(false);
const user = computed(() =>
  props.user.map((item) => ({
    ...item,
    contact_information: item.contact_information.map((contact) => ({
      ...contact,
      company:
        typeof contact.company === "object"
          ? contact.company
          : {
              name: contact.company,
              image: contact.image,
            },
    })),
  }))
);
/* -------- METHODS -------- */
const handleCompanyError = (event: any) => {
  event.target.src = NoCompanyImage;
};
/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
