<template>
  <div
    class="w-full h-full flex flex-col bg-main-title border-l-[0.0625rem] border-[#434343]">
    <div
      class="w-full flex items-center sticky top-0 justify-center bg-[#141414] h-[3.1875rem] border-b-[0.0625rem] border-[#434343] z-20 py-2">
      <FileUpload
        v-if="!dragging"
        @select="handleSelect"
        multiple
        mode="basic"
        accept="image/*"
        customUpload
        :auto="true"
        chooseLabel="Add"
        @uploader="handleUpload"
        class="!bg-transparent !text-sm !font-medium disabled:pointer-events-none disabled:opacity-75 text-white !w-[9.375rem]">
        <template #uploadicon><i class="fa fa-plus"></i></template>
      </FileUpload>
      <button
        @click="dragging = true"
        class="flex items-center justify-center gap-[0.3125rem] disabled:pointer-events-none disabled:opacity-75 !w-[9.375rem]">
        <span>
          <img
            src="https://development.demo.mybizly.com/_nuxt/reorder-icon.3aaec8f1.svg"
            alt="Reorder-icon" />
        </span>
        <span class="text-[0.875rem] text-[#fff] font-[500] m-0">Reorder</span>
      </button>
    </div>
    <div
      class="flex flex-col items-center justify-center py-[1.25rem] px-[3.5rem] text-white h-full"
      v-if="uploads.length === 0 && !dragging">
      <span class="fa-stack fa-2xl text-white">
        <i class="fa-kit fa-plain-gallery fa-stack-2x"></i>
        <i
          class="text-xl text-main-lightBg fa fa-images translate-x-5 translate-y-1"></i>
      </span>
      <h1 class="text-center">
        Drag and drop a single or multiple images here
      </h1>
      <FileUpload
        @select="handleSelect"
        multiple
        mode="basic"
        accept="image/*"
        customUpload
        :auto="true"
        chooseLabel="Select from computer"
        @uploader="handleUpload"
        class="!bg-switcherBlue-500 !border-0 focus:!ring-0 !hover:bg-switcherBlue-500/70 mt-4 !px-4 !py-1.5" />
    </div>
    <div
      class="flex flex-col gap-4 w-[375px] aspect-square mx-auto py-5"
      v-else-if="uploads.length !== 0 && !dragging">
      <div
        class="relative w-full"
        v-for="(image, index) in uploads"
        v-if="editing === null">
        <img
          :src="image.thumbnail.default"
          alt=""
          class="rounded object-cover w-full" />
        <div
          class="p-2 rounded-3xl flex items-center justify-center gap-2 bg-black/30 text-white absolute top-2 left-3 !cursor-default">
          <CircleIcon class="!p-0" size="1rem">
            <i class="fa-light fa-image"></i>
          </CircleIcon>
          <p v-if="uploads.length > 1" class="text-sm">
            {{ index + 1 }}/{{ uploads.length }}
          </p>
        </div>
        <div class="flex items-center gap-4 absolute top-2 right-3 text-white">
          <CircleIcon
            class="bg-black/50 text-white"
            size="1.7rem"
            @click="editing = index">
            <i class="fa-light fa-crop-simple"></i>
          </CircleIcon>
          <CircleIcon
            class="bg-black/50 text-white"
            size="1.7rem"
            @click="() => handleRemove(index)">
            <i class="fa-light fa-trash-can"></i>
          </CircleIcon>
        </div>
      </div>
      <div class="relative flex flex-col gap-4" v-else>
        <div class="relative">
          <cropper
            ref="cropperEl"
            class="cropper size-full"
            :src="uploads[editing]['thumbnail']['default']"
            image-restriction="stencil"
            :stencil-props="{
              aspectRatio: uploads[editing]['aspect'] ?? null,
            }"></cropper>
          <CropperAspectMenu
            ref="cropperAspectMenuRef"
            @update="handleUpdateCropperAspect"
            :default_aspect="uploads[editing]['default_aspect']" />
          <div class="absolute bottom-2 left-4">
            <CircleIcon
              class="bg-black/50 text-white"
              size="1.7rem"
              @click="toggleCropperAspectMenuRef">
              <i class="fa-light fa-crop-simple"></i>
            </CircleIcon>
          </div>
        </div>
        <div class="flex justify-end items-center gap-4">
          <button
            @click="editing = null"
            class="mt-4 border-white text-white rounded text-xs w-[100px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent bg-red-600 disabled:hover:border-[1px] border-0 disabled:border-main-disabled">
            Cancel
          </button>
          <button
            @click="handleCrop"
            class="mt-4 border-white text-white rounded text-xs w-[100px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent bg-switcherBlue-500 disabled:hover:border-[1px] border-0 disabled:border-main-disabled">
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="p-10" v-else>
      <draggable
        class="columns-2 space-y-4"
        item-key=".image"
        v-model="uploads"
        v-bind="dragOptions"
        tag="div">
        <template #item="{ element, index }">
          <div class="relative break-inside-avoid">
            <img
              :src="element['thumbnail']['default']"
              alt=""
              class="rounded object-fit object-center" />
            <p
              class="absolute top-1 left-2 h-6 w-6 flex items-center justify-center rounded-full text-white text-sm bg-main-secondary/70 leading-tight tracking-tighter">
              {{ index + 1 }}
            </p>
          </div>
        </template>
      </draggable>
      <div class="flex justify-end items-center">
        <button
          @click="dragging = false"
          class="mt-4 border-white text-white rounded text-xs w-[100px] h-[40px] disabled:bg-transparent disabled:hover:bg-transparent bg-switcherBlue-500 disabled:hover:border-[1px] border-0 disabled:border-main-disabled">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, computed, onMounted, watch } from "vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import draggable from "vuedraggable";
import { useToastr } from "@/composables/useToastr";
import CropperAspectMenu from "./CropperAspectMenu.vue";
const { error } = useToastr();
import { getImageDimensions } from "@/utils/commonFunctions.js";

interface imageUploadObj {
  thumbnail: {
    default: string;
  };
  order: number;
  valid: boolean;
}
const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});
const editing = ref<number | null>(null);

const cropperAspectMenuRef = ref();
const toggleCropperAspectMenuRef = (event) => {
  cropperAspectMenuRef.value.toggle(event);
};
const handleUpdateCropperAspect = (e) => {
  if (editing.value !== null && editing.value !== undefined) {
    uploads.value[editing.value]["aspect"] = e;
  }
};
const MAXFILESIZE = 20 * 1048576; // 20MB
const uploads = ref<imageUploadObj[]>(props.modelValue.items || []);
const selectedIdx = ref(0);
const customThumbnail = ref();
const dragging = ref(false);
const dragOptions = {
  animation: 200,
  group: "description",
  disabled: false,
  ghostClass: "ghost",
};
const handleSelect = (e) => {
  e.files.forEach((element, index) => {
    if (element.size > MAXFILESIZE) {
      e.files.splice(index, 1);
      error("Maximum file size is 20MB");
    }
  });
};
const cropperEl = ref();
const Fileuploader = ref();
const handleUpload = async (event) => {
  event.files.forEach((file, idx) => {
    const reader = new FileReader();
    reader.onerror = function (error) {
      console.error(error);
    };
    reader.onload = async function (e) {
      const base64data = e.target.result;

      const { height, width } = await getImageDimensions(base64data);
      const aspect = width / height;
      uploads.value.push({
        thumbnail: {
          default: base64data,
        },
        aspect,
        default_aspect: aspect,
        order: idx,
        valid: true,
      });
      if (uploads.value.length === 1)
        props.modelValue.thumbnail = {
          default: base64data,
          square: base64data,
          video: base64data,
        };
    };
    reader.onerror = function (error) {
      console.error(error);
    };
    reader.readAsDataURL(file);
  });
};

const handleRemove = (idx) => {
  uploads.value.splice(idx, 1);
};
const handleCrop = () => {
  const { canvas } = cropperEl.value.getResult();
  if (canvas) {
    const base64 = canvas.toDataURL();
    if (editing.value !== null) {
      props.modelValue.items[editing.value].thumbnail = {
        default: base64,
      };
      uploads.value[editing.value]["thumbnail"] = {
        default: base64,
      };
    }
  }
  editing.value = null;
};
watch(
  () => uploads.value,
  () => {
    props.modelValue.items = uploads.value;
  },
  { deep: true },
);
</script>
