<template>
  <Dialog
    :pt="{
      header: 'hidden',
      content: 'p-0 w-full flex flex-col relative',
      root: '!bg-white max-w-[700px] w-full rounded-[16px]  scroll-hidden',
    }"
    :visible="true"
    modal
    :closable="false">
    <section
      id="header"
      class="p-2 bg-surface-50 border-b-[1px] border-surface-300 sticky top-0 z-20 rounded-t-[16px]">
      <div class="flex justify-between items-center">
        <h1 class="font-poppins text-xl flex-1 text-center">Social Links</h1>
        <CircleIcon
          class="bg-surface-100 text-primary-500 hover:bg-surface-200"
          @click="$emit('close')">
          <i class="far fa-times"></i>
        </CircleIcon>
      </div>
    </section>
    <section
      id="content"
      class="px-4 py-2 flex flex-col gap-2 overflow-y-auto scroll-hidden max-h-[80vh]">
      <InfoCard customClass="px-4 py-2">
        <template #content>
          <div class="flex gap-4 items-center">
            <Dropdown
              v-model="newSocial.value"
              :options="socialNetworksArray"
              optionLabel="label"
              optionValue="value"
              placeholder="Select a social network"
              :pt="{
                root: '!border-none focus:!ring-0',
                wrapper: 'max-h-[200px] overflow-y-auto  rounded-b-lg',
                item: '!bg-white !text-surface-700 hover:!bg-surface-100 hover:!text-surface-700 focus-visible:!ring-surface-100 focus-visible:!text-black',
              }"
              :ptOptions="{ mergeProps: true }"
              @change="handleSocialChange($event)">
              <template #option="{ option }">
                <div class="flex gap-2 items-center">
                  <i :class="option['icon']"></i>
                  <p class="text-inherit">
                    {{ option["label"] }}
                  </p>
                </div>
              </template>
            </Dropdown>

            <div
              class="flex-1 flex items-stretch overflow-x-auto scroll-hidden"
              v-if="newSocial.value">
              <span
                class="flex items-center whitespace-nowrap text-center text-[12px] md:text-sm font-normal text-[#8F9FB8]"
                id="basic-addon3">
                {{ newSocial.placeholder }}
              </span>
              <InputText
                @input=""
                v-model="newSocial.url"
                class="w-full !pl-0 !border-transparent focus:!ring-0 z-10"
                :class="{ '!text-red-500': social_exists }"></InputText>
            </div>
            <CircleIcon
              v-if="!social_exists"
              :class="
                cn('ms-auto', {
                  'bg-surface-100 text-primary-500 hover:bg-surface-100 cursor-not-allowed':
                    newSocial.value == '',
                  'bg-switcherBlue-500 text-white hover:bg-switcherBlue-600':
                    newSocial.value && newSocial.url,
                })
              "
              @click="handleAddSocial"
              size="1.5rem">
              <i class="far fa-plus text-xs"></i>
            </CircleIcon>
          </div>
        </template>
      </InfoCard>
      <p v-if="social_exists" class="text-red-500 text-xs">
        Entry already exists!
      </p>
      <draggableComponent
        class="draggable-container flex flex-col gap-2"
        item-key="order"
        :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }"
        v-model="form.socials"
        v-bind="dragOptions"
        handle=".drag-handle">
        <template #item="{ element, index }">
          <InfoCard customClass="px-4 py-2">
            <template #content>
              <div class="flex gap-4 items-center">
                <i
                  class="fa fa-grip-dots-vertical text-main-disabled cursor-pointer drag-handle"></i>
                <i
                  :class="
                    cn(
                      'text-2xl drag-handle',
                      socialNetworks[element.network.toLowerCase()].icon,
                    )
                  "></i>
                <div
                  class="flex-1 flex items-stretch overflow-x-auto scroll-hidden">
                  <span
                    class="flex items-center whitespace-nowrap text-center text-[12px] md:text-sm font-normal text-[#8F9FB8]"
                    id="basic-addon3">
                    {{
                      socialNetworks[element.network.toLowerCase()].placeholder
                    }}
                  </span>
                  <InputText
                    @input=""
                    v-model="element.url"
                    class="w-full !pl-0 !border-transparent focus:!ring-0 z-10"></InputText>
                </div>
                <CircleIcon
                  class="bg-primary-500 text-white hover:bg-primary-600"
                  @click="handleRemove(index)"
                  size="1.5rem">
                  <i class="far fa-times text-xs"></i>
                </CircleIcon>
              </div>
            </template>
          </InfoCard>
        </template>
      </draggableComponent>
    </section>
    <section
      id="footer"
      class="bg-surface-50 border-t border-surface-300 sticky bottom-0 z-20 rounded-b-[16px]">
      <div class="flex justify-end items-center py-2.5 px-4 gap-2">
        <Button
          label="Save"
          :disabled="!form.isDirty"
          :loading="form.processing"
          class="min-w-[150px]"
          @click="handleSave"></Button>
      </div>
    </section>
  </Dialog>
</template>

<script lang="ts" setup>
import { reactive, ref, computed, onMounted, watch } from "vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import type { SocialNetworkInterface } from "@/core/interfaces";
import { socialNetworks } from "@/core/interfaces/SocialNetwork";
import InfoCard from "@/components/general/CustomCard.vue";
import { cn } from "@/utils/cn";
import { router, useForm } from "@inertiajs/vue3";
import InputText from "primevue/inputtext";
import type { PropType } from "vue";
import draggableComponent from "vuedraggable";
import { API } from "@/core/api";
import { useToastr } from "@/composables/useToastr";

/* -------- PROPS -------- */
const props = defineProps({
  socials: {
    required: true,
    type: Array as PropType<SocialNetworkInterface[]>,
  },
});
/* -------- COMPOSABLE -------- */
const bizCardInfoAPI = new API.BizcardInfo();
const { success, error } = useToastr();

/* -------- STATE -------- */
const emit = defineEmits(["close"]);
const socialNetworksArray = ref(
  Object.keys(socialNetworks).map((key) => ({
    label: socialNetworks[key].label,
    icon: socialNetworks[key].icon,
    placeholder: socialNetworks[key].placeholder,
    value: key,
  })),
);
const dragOptions = {
  animation: 200,
  group: "description",
  disabled: false,
  ghostClass: "ghost",
};

const form = useForm({
  socials:
    props.socials.map((social: SocialNetworkInterface) => ({
      ...social,
      url: social.url.replace(
        socialNetworks[social.network.toLowerCase()].placeholder,
        "",
      ),
    })) ?? [],
});

const newSocial = reactive({
  value: "",
  placeholder: "",
  url: "",
});
/* -------- METHODS -------- */
const handleSocialChange = (selectedValue: any) => {
  // Find the corresponding social network object based on the selected value
  const selectedSocial = socialNetworksArray.value.find(
    (social) => social.value === selectedValue.value,
  );

  // Create a deep copy of the selected social network object
  if (selectedSocial) {
    newSocial.value = selectedSocial.value;
    newSocial.placeholder = selectedSocial.placeholder;
  } else {
    newSocial.value = "";
  }
};
const social_exists = computed(
  () =>
    form.socials.findIndex(
      (s) =>
        s.network.toLowerCase() === newSocial.value.toLowerCase() &&
        s.url === newSocial.url,
    ) !== -1,
);
const handleAddSocial = () => {
  if (!newSocial.value || !newSocial.url) return;
  form.socials.unshift({
    network: newSocial.value,
    url: newSocial.url,
  });

  newSocial.value = "";
  newSocial.url = "";
  newSocial.placeholder = "";
};

const handleRemove = (index: number) => {
  form.socials.splice(index, 1);
};

const handleSave = async () => {
  form.processing = true;
  try {
    const response = await bizCardInfoAPI.updateSocials(form);
    if (response.code === "social_networks_updated") {
      success("Social links updated successfully");
      form.isDirty = false;
      router.reload();
      emit("close");
    } else if (response.error) {
      error(response.error);
    } else {
      error("Failed to update socials: Unknown error");
    }
  } catch (e: any) {
    console.error(e);
  } finally {
    form.processing = false;
  }
};
/* -------- HOOKS -------- */

/* -------- WATCHERS -------- */
</script>
<style></style>
