<template>
  <Layout
    :header="{
      title: user.full_name,
      subtitle: user.roles.map((v) => v.name),
      user: user,
      card,
    }"
  >
    <template #subheaderActions>
      <CircleIcon
        v-if="is_incoming_request || is_outbound_request"
        class="text-white"
        :class="{
          'bg-orange-300': is_outbound_request,
          'bg-green-500': is_incoming_request,
        }"
        @click="toggleRequestsMenu"
        size="2.5rem"
      >
        <i
          class="fa fa-sharp"
          :class="{
            'fa-clock': is_outbound_request,
            'fa-envelope-open-text': is_incoming_request,
          }"
        ></i>
      </CircleIcon>
      <CircleIcon
        v-for="action in ACTIONS.filter((action) => action.show)"
        :key="action.icon"
        :class="
          cn('bg-main-secondary hover:bg-main-secondary/80 text-white', action.class)
        "
        @click="action.clickEvent"
        size="2.5rem"
      >
        <i :class="action.icon"></i>
      </CircleIcon>
      <CircleIcon
        v-if="!owned_profile"
        class="bg-main-secondary hover:bg-main-secondary/80 text-white"
        @click="toggleHeaderMenu"
        size="2.5rem"
      >
        <i class="fa fa-ellipsis-vertical"></i>
      </CircleIcon>
    </template>
    <div
      :class="
        cn('h-[calc(100vh-100px)] overflow-y-auto overscroll-contain scroll-hidden', {
          'overscroll-auto': bizCardStore.homeFeed.lazyLoad.no_more_results,
        })
      "
      v-infinite-scroll="handleScroll"
      scroll-region
    >
      <Header
        v-bind="props"
        @updateAvatar=""
        @toggleRequestsMenu="toggleRequestsMenu"
      ></Header>
      <Feed v-bind="props" ref="feedContainer"></Feed>
    </div>

    <!-- MENUS -->
    <Menu
      id="overlay_menu"
      :model="ellipsesMenu.filter((i) => i.active)"
      class="w-[21rem] content_menu overflow-hidden shadow-[0_2px_15px_0_rgba(0,0,0,0.3)]"
      ref="headerMenu"
      :popup="true"
      :pt="{ content: 'hover:!bg-transparent' }"
      :ptOptions="{ mergeProps: true }"
    >
      <template #start>
        <div
          class="py-2.5 px-4 bg-lightBg-500 flex items-center justify-center font-medium border-b border-main-divider text-lg"
        >
          {{ user.full_name }}
        </div>
      </template>
      <template #item="{ item }">
        <div
          :class="{
            'pointer-events-none text-main-disabled hover:bg-white': item.disabled,
          }"
        >
          <a
            class="flex items-center gap-4 py-3 px-4 w-full"
            :class="{
              'border-b border-main-divider': item.border,
              'hover:bg-main-lightGray/20': !item.disabled,
            }"
            v-if="item.type == 'external_link'"
            :href="item.href"
            target="_blank"
          >
            <CircleIcon>
              <i :class="item.icon"></i>
            </CircleIcon>

            <div class="flex flex-col gap-[2px] items-start justify-start">
              <p
                class="mb-0 w-max text-[16px] font-medium leading-5"
                :class="{
                  'text-[#ff3b30]': item.danger,
                }"
              >
                {{ item?.label }}
              </p>
              <small
                class="text-xs font-normal leading-4 text-main-secondary text-left"
                v-if="item.description"
              >
                {{ item?.description }}
              </small>
            </div>
          </a>
          <Link
            v-else-if="item.type === 'inertia_link'"
            :href="item.href"
            class="flex items-center gap-4 py-3 px-4 w-full"
            :class="{
              'border-b border-main-divider': item.border,
              'hover:bg-main-lightGray/20': !item.disabled,
            }"
          >
            <CircleIcon :class="{ 'bg-[#ff3b30]/30': item.danger }">
              <i :class="item.icon"></i>
            </CircleIcon>

            <div class="flex flex-col gap-[2px] items-start justify-start">
              <p
                class="mb-0 w-max text-[16px] font-medium leading-5"
                :class="{
                  'text-[#ff3b30]': item.danger,
                }"
              >
                {{ item?.label }}
              </p>
              <small
                class="text-xs font-normal leading-4 text-main-secondary text-left"
                v-if="item.description"
              >
                {{ item?.description }}
              </small>
            </div>
          </Link>
          <button
            v-else-if="item.type === 'button'"
            class="flex items-center gap-[12px] py-3 px-4 w-full"
            :class="{
              'border-b border-main-divider': item.border,
              'hover:bg-main-lightGray/20 cursor-pointer': !item.disabled,
            }"
            @click="item.clickEvent"
          >
            <CircleIcon
              size="2rem"
              :class="{
                'bg-red-200': item.danger,
              }"
            >
              <i
                :class="
                  cn(item.icon, {
                    'text-red-500': item.danger,
                  })
                "
              ></i>
            </CircleIcon>
            <div class="flex flex-col gap-[2px] items-start justify-start">
              <p
                class="mb-0 w-max text-[16px] font-medium leading-5"
                :class="{
                  'text-[#ff3b30]': item.danger,
                }"
              >
                {{ item?.label }}
              </p>
              <small
                class="text-xs font-normal leading-4 text-main-secondary text-left"
                v-if="item.description"
              >
                {{ item?.description }}
              </small>
            </div>
          </button>
        </div>
      </template>
    </Menu>
    <Menu
      v-if="is_incoming_request || is_outbound_request"
      id="REQUESTS_MENU"
      :model="REQUESTS.filter((r) => r.active)"
      class="w-[21rem] content_menu overflow-hidden shadow-[0_2px_15px_0_rgba(0,0,0,0.3)]"
      ref="incomingRequestMenu"
      :popup="true"
      :pt="{
        root: 'min-w-[12rem] rounded-md bg-surface-0 text-surface-700 p-2',
        content: 'hover:!bg-white',
      }"
    >
      <template #item="{ item }">
        <button :class="item.className" @click="item.clickEvent">
          <i v-if="item.icon" :class="item.icon"></i>
          {{ item.title }}
        </button>
      </template>
    </Menu>
    <ContentDialog v-if="ContentDialogVisible" @close="ContentDialogVisible = false" />
  </Layout>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, type PropType, onUnmounted } from "vue";
import Layout from "@/components/bizcard/home/Layout.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import { type BizCardInterface, type UserInterface } from "@/core/interfaces";
import { DialogType, useDialogStore } from "@/store/Dialog";
import { useUserStore } from "@/store/User";
import { FeedType, useUserBizcardStore } from "@/store/UserBizcard";
import { useContactsStore } from "@/store/Contacts";
import { useToastr } from "@/composables/useToastr";
import { cn } from "@/utils/cn";
import { type MenuButtonInterface } from "@/core/interfaces";
import { Link } from "@inertiajs/vue3";
import Header from "./Header.vue";
import Feed from "./Feed.vue";
import { route } from "ziggy-js";
import { vInfiniteScroll } from "@vueuse/components";
import ContentDialog from "@/components/dialogs/content/Main.vue";

/* ---------- STORES ----------*/
const contactsStore = useContactsStore();
const dialogStore = useDialogStore();
const bizCardStore = useUserBizcardStore();
const userStore = useUserStore();

/* ---------- REACTIVE DATA ----------*/
const { success, error } = useToastr();
const headerMenu = ref();
const feedContainer = ref();
const incomingRequestMenu = ref();
const props = defineProps({
  card: {
    required: true,
    type: Object as PropType<BizCardInterface>,
  },
  user: {
    required: true,
    type: Object as PropType<UserInterface>,
  },
});
const ContentDialogVisible = ref(false);
const friends = computed(() => userStore.friends ?? []);
const received_requests = computed(
  () => userStore.friend_requests.received ?? [],
);
const sent_requests = computed(() => userStore.friend_requests.sent ?? []);
const is_incoming_request = computed(
  () =>
    received_requests.value.findIndex((r) => r.sender.id === props.user.id) !==
    -1,
);
const is_outbound_request = computed(
  () =>
    sent_requests.value.findIndex((r) => r.recipient.id === props.user.id) !==
    -1,
);
const is_friend = computed(
  () => friends.value.findIndex((f) => f.id === props.user.id) !== -1,
);

const is_favorite = computed(
  () => contactsStore.favorites.findIndex((f) => f.id === props.user.id) !== -1,
);
const owned_profile = computed(() => bizCardStore.own_profile);

const isEditing = computed(() => bizCardStore.isEditing);
const ACTIONS = computed(() => [
  {
    icon: "fa-regular fa-circle-plus",
    show: isEditing.value,
    clickEvent: () => {
      ContentDialogVisible.value = true;
    },
  },
  {
    icon: "fa-regular fa-pen",
    class: isEditing.value
      ? "bg-switcherBlue-500 hover:bg-switcherBlue-600"
      : "",
    show: owned_profile.value,
    clickEvent: bizCardStore.toggleEditing,
  },
  {
    icon: "fa-regular fa-share",
    show: true,
    clickEvent: async () => {
      const permissionStatus = await navigator.permissions.query({
        name: "clipboard-write",
      });
      if (
        permissionStatus.state === "granted" ||
        permissionStatus.state === "prompt"
      ) {
        const url = route("user.profile.index", props.user?.public_username);
        await navigator.clipboard.writeText(url);
        success(`Copied to clipboard: ${url}`);
      }
    },
  },
]);
/* ----- MENUS -----*/
const REQUESTS = computed(() => [
  {
    active: is_incoming_request.value,
    title: "Accept connection request",
    className:
      "rounded-md px-1 py-3 w-full bg-green-500 text-white transition-all",
    clickEvent: handleAcceptConnection,
  },
  {
    active: is_incoming_request.value,
    title: "Decline connection request ",
    className:
      "rounded-md px-1 py-3 w-full hover:bg-lightBg-500 transition-all",
    clickEvent: handleDeclineConnection,
  },
  {
    active: is_outbound_request.value,
    title: "Withdraw connection request",
    icon: "fa fa-user-xmark",
    className:
      "flex items-center justify-center gap-2 rounded-md px-1 py-3 w-full hover:bg-lightBg-500 transition-all",
    clickEvent: () => {
      userStore.withdrawConnectionRequest(
        sent_requests.value.find((r) => r.recipient.id === props.user.id),
      );
    },
  },
]);
const ellipsesMenu = computed<MenuButtonInterface[]>(() => {
  return [
    {
      active: is_friend.value,
      disabled: true,
      label: "Notes",
      icon: "fa fa-memo-pad",
      type: "button",
      border: true,
      clickEvent: () => {},
    },
    {
      active: is_friend.value,
      disabled: true,
      label: "Message",
      icon: "fa fa-comment",
      type: "button",
      clickEvent: () => handleOpenMobileAppDialog,
    },
    {
      active: true,
      disabled: true,
      label: "Share bizlyCard",
      icon: "fa fa-share",
      type: "button",
      border: true,
      clickEvent: () => {},
    },
    {
      active: is_friend.value,
      disabled: false,
      label: is_favorite.value ? "Remove from favorites" : "Add to favorites",
      icon: "fa fa-star",
      type: "button",
      clickEvent: () => {
        contactsStore.toggleFavorite(props.user);
      },
    },
    {
      active: is_friend.value,
      disabled: false,
      label: "Remove connection",
      icon: "fa fa-trash-can",
      type: "button",
      clickEvent: async () => {
        try {
          userStore.removeConnection(props.user.id);
        } catch (err: any) {
          error(err.message);
        }
      },
    },
    {
      active: true,
      disabled: false,
      label: `Report ${props.user.full_name}`,
      icon: "fa-sharp fa-regular fa-brake-warning",
      type: "external_link",
      href: "https://form.asana.com/?k=fNK3H3eAMYJxDz8Mhm5nPw&d=1200288379680184",
    },
    {
      active: true,
      danger: true,
      disabled: false,
      label: `Block ${props.user.full_name}`,
      icon: "fa fa-shield",
      type: "button",
      clickEvent: () => {
        userStore.blockUser(props.user.id);
      },
    },
  ];
});
/* ---------- FUNCTIONS ----------*/
const handleScroll = async () => {
  if (feedContainer.value) {
    await feedContainer.value.fetchPosts();
  }
};
const toggleHeaderMenu = (event) => {
  if (headerMenu.value) headerMenu.value.toggle(event);
};
const toggleRequestsMenu = (event) => {
  if (incomingRequestMenu.value) incomingRequestMenu.value.toggle(event);
};

const handleAcceptConnection = () => {
  userStore.acceptConnectionRequest(
    received_requests.value.find((r) => r.sender.id === props.user.id),
  );
};
const handleDeclineConnection = () => {
  userStore.declineConnectionRequest(
    received_requests.value.find((r) => r.sender.id === props.user.id),
  );
};
const handleOpenMobileAppDialog = () => {
  dialogStore.show(DialogType.MOBILE_APP);
  dialogStore.setEvents(DialogType.MOBILE_APP, {
    close: () => dialogStore.hide(DialogType.MOBILE_APP),
  });
};

onMounted(() => {
  bizCardStore.init(props.card, props.user);
});
</script>
<style scoped>
.radial-gradient-overlay {
  background: radial-gradient(
    111% 190% at 7.9% 100%,
    rgba(0, 0, 0, 0.7) 20.6%,
    transparent 70%
  );
  height: 100%;
  width: 100%;
}
</style>
