<template>
  <div
    class="w-full border-box min-lg:w-[450px] px-4 md:px-[0] min-lg:min-w-[450px] 2xl:min-w-[550px] 2xl:w-[550px] h-[100%] box-border order-[3] lg:order-[2]">
    <InfiniteScroll :callback="fetch" ref="feedContainer" id="feedContainer">
      <div
        class="flex items-center mx-auto pt-[0px] md:pt-[30px] gap-4 w-full px-4">
        <Button
          v-for="type in types"
          :key="type"
          size="small"
          class="capitalize"
          :class="{
            '!bg-switcherBlue-500/15': homeFeedStore.selectedTab === type,
            '!text-text-700 hover:!text-primaryBlue-500':
              homeFeedStore.selectedTab !== type,
          }"
          :pt="HomeFeedPreset"
          @click="homeFeedStore.setSelectedTab(type)">
          {{ type }}
        </Button>
      </div>
      <div class="flex flex-col gap-6 p-4" v-if="posts.length">
        <div
          class="rounded-2xl social-post-feed scroll-smooth w-full max-w-[500px] cursor-pointer"
          v-for="post in posts"
          :key="post.id">
          <div v-if="!post.hidden">
            <SocialPost :post="post" />
            <div class="activity-feed-footer">
              <Footer :data="post" />
            </div>
          </div>
          <HiddenPost :post="post" v-else />
        </div>
      </div>
      <div v-else class="md:pt-[30px]">
        <Skeleton width="10rem" class="mb-2"></Skeleton>
        <Skeleton width="5rem" class="mb-2"></Skeleton>
        <Skeleton height="2rem" class="mb-2"></Skeleton>
        <Skeleton width="10rem" height="4rem"></Skeleton>
      </div>
    </InfiniteScroll>
  </div>
</template>
<script lang="ts" setup>
import HomeFeedPreset from "@/assets/tailwind-preset/button/home_feed";
import SocialPost from "@/pages/social-posts/parts/SocialPost.vue";
import { computed, ref } from "vue";
import { onMounted, watch, nextTick, onUpdated, onBeforeUnmount } from "vue";
import { vInfiniteScroll } from "@vueuse/components";
import Footer from "@/components/social-posts/Footer.vue";
import { route } from "ziggy-js";
import { router } from "@inertiajs/vue3";
import { useHomeFeedStore } from "@/store/HomeFeed";
import { HomeFeedType } from "@/core/enums";
import HiddenPost from "@/pages/social-posts/Hidden.vue";
import _debounce from "lodash/debounce";
import InfiniteScroll from "@/components/infinite-scroll/InfiniteScroll.vue";
import throttle from "lodash/throttle";
const homeFeedStore = useHomeFeedStore();
const posts = computed(() => homeFeedStore.items ?? []);
const loading = ref(false);
const types = ref([HomeFeedType.Community, HomeFeedType.Connections]);
const feedContainer = ref<HTMLElement | null>();
onMounted(() => {
  homeFeedStore.init();
});

onUpdated(() => {
  //setScrollPosition();
});

const handleScroll = _debounce(() => {
  //homeFeedStore.setScroll(feedContainer?.value?.scrollTop || 0);
}, 250);

const setScrollPosition = () => {
  nextTick(() => {
    const savedPosition =
      homeFeedStore.data[homeFeedStore.selectedTab].scrollPosition;
    if (savedPosition && feedContainer.value) {
      feedContainer.value.scrollTop = savedPosition;
    }
  });
};
const fetch = throttle(async () => {
  if (homeFeedStore.initialized && !loading.value) {
    try {
      loading.value = true;
      await homeFeedStore.fetch();
    } catch (err) {
      console.error(err);
    } finally {
      loading.value = false;
    }
  }
}, 150);
</script>
