<template>
  <div class="bg-white rounded-md shadow-default w-full p-4">
    <h1 class="mb-2">
      {{ collaboratorsCount }} Collaborator{{
        collaboratorsCount > 1 ? "s" : ""
      }}
    </h1>
    <div class="flex flex-col">
      <div class="" v-if="ownerWithRoles">
        <UserCard
          :user="ownerWithRoles"
          :showLocation="false"
          :ownerTag="true" />
        <hr
          class="bg-main-divider h-[1px] w-full my-2"
          v-if="collaborators.length" />
      </div>
      <div
        v-for="(collaborator, index) in collaborators"
        :key="collaborator.id">
        <UserCard class="" :user="collaborator" :showLocation="false" />
        <hr
          class="bg-main-divider h-[1px] w-full my-2"
          v-if="collaborators.at(-1) !== collaborators.at(index)" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, watch, type PropType, onUpdated } from "vue";
import UserCard from "@/components/general/UserCard.vue";
import type { RolesInterface, UserInterface } from "@/core/interfaces";
const props = defineProps({
  collaborators: {
    type: Array as PropType<UserInterface[]>,
    required: true,
  },
  owner: {
    type: Object as PropType<UserInterface>,
    default: null,
  },
  ownerRoles: {
    type: Array as PropType<RolesInterface[]>,
    default: () => [],
  },
});

const ownerWithRoles = computed<UserInterface>(() => {
  console.log("updated!");
  if (props.ownerRoles.length === 0) return null;
  else return { ...props.owner, roles: [...props.ownerRoles] } as UserInterface;
});
console.log(ownerWithRoles.value);
const collaboratorsCount = computed(() => {
  let count;
  count = props.collaborators.length;
  if (props.owner) count++;
  return count;
});
</script>
